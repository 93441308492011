import React, { useCallback, useMemo, useState, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon} from "@heroicons/react/solid";
import { CogIcon } from "@heroicons/react/outline";
import { useDropzone } from "react-dropzone";
import ImageItem from "./types/ImageItem";
import * as Magick from "wasm-imagemagick";
import { saveAs } from "file-saver";
import cuid from 'cuid';
import prettyBytes from 'pretty-bytes';

enum SupportedFormat {
  PNG,
  JPG,
  GIF
}

const getFormatLabel = (format: SupportedFormat): string => {
  switch (format) {
    case SupportedFormat.PNG:
      return 'PNG';
    case SupportedFormat.JPG:
      return 'JPG';
    case SupportedFormat.GIF:
      return 'GIF';
    default:
      return '';
  }
};

type ProcessImageOptions = {
  outputFormat: SupportedFormat;
  compression: number;
  scale: number;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const outputFormatOptions = [
  { key: SupportedFormat.PNG, label: 'PNG' },
  { key: SupportedFormat.JPG, label: 'JPG' },
  { key: SupportedFormat.GIF, label: 'GIF' },
];

const processImage = (image: ImageItem, options: ProcessImageOptions): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    const fileByteArray = [];

    reader.readAsArrayBuffer(image.input);

    reader.onloadend = async function (evt) {
      if (evt?.target?.readyState === FileReader.DONE) {
        const arrayBuffer = evt.target.result as ArrayBuffer;

        const array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < array.length; i++) {
          fileByteArray.push(array[i]);
        }

        const sourceExt = image.input.name.split('.').pop();

        const inputFiles = [{ name: `sourceFile.${sourceExt}`, content: array }];

        let command: string[] = [];

        let outExtension = "png";

        switch (options.outputFormat) {
          case SupportedFormat.JPG:
            outExtension = 'jpg'
            break;
          case SupportedFormat.GIF:
            outExtension = 'gif'
            break;
          default:
            break;
        }

        command = [
          "convert", `sourceFile.${sourceExt}`,
          "-resize", `${options.scale}%`,
          // "-resize", `${options.scale}%`,
          `out$.${outExtension}`];

        console.log({
          command: command.join(", "),
          content: inputFiles[0].content,
        });

        const processedFiles = await Magick.Call(inputFiles, command);

        const [result] = processedFiles;

        resolve(result["blob"]);
      }
    };
  });
};

const App = () => {
  const [ isProcessing, setIsProcessing ] = useState<boolean>(false);
  const [ items, setItems ] = useState<ImageItem[]>([]);
  const [ outputFormat, setOutputFormat ] = useState<SupportedFormat>(SupportedFormat.PNG);
  const [ compression ] = useState<number>(100);
  const [ scale, setScale ] = useState<number>(100);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (isProcessing) { return; }

    setItems((i) => [
      ...i,
      ...acceptedFiles.map((file: File) => ({
        id: cuid(),
        input: file,
        output: null,
      })),
    ]);
  }, [isProcessing]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const processQueue = useCallback(async () => {
    setIsProcessing(true);

    const queue = [...items.filter((item) => !item.output)];
    const options: ProcessImageOptions = {
      outputFormat,
      compression,
      scale,
    };

    for (const item of queue) {
      try {
        const split = item.input.name.split(".");

        split.pop();

        let outExtension = "png";

        switch (options.outputFormat) {
          case SupportedFormat.JPG:
            outExtension = 'jpg'
            break;
          case SupportedFormat.GIF:
            outExtension = 'gif'
            break;
          default:
            break;
        }

        const data = await processImage(item, options);
        const name = [...split, outExtension].join('.');

        setItems((it) =>
          it.map((i) => {
            if (i.id !== item.id) {
              return i;
            }

            return {
              ...item,
              output: {
                data,
                name,
              },
            };
          })
        );
      } catch (error) {
        setItems((it) =>
          it.map((i) => {
            if (i.id !== item.id) {
              return i;
            }

            return {
              ...item,
              processingError: 'An exception was thrown while processing image'
            };
          })
        );
      }
    }

    setIsProcessing(false);
  }, [items, outputFormat, compression, scale]);

  const queue = useMemo(() => items.filter((item) => !item.output), [items]);
  const processed = useMemo(() => items.filter((item) => item.output), [items]);

  return (
    <div className="bg-white h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">Image Tools</h2>
          <p className="mt-4 text-lg text-gray-500">
            Quickly convert, scale, and compress images from the comfort of your
            browser.
          </p>
        </div>
        <div className="flex flex-col">
          <div
            className={`border-dashed border-4 ${
              isProcessing ? "border-gray-200" : "border-gray-600"
            } p-4 h-28 cursor-pointer my-4 mx-4 text-center flex justify-center items-center`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className={isProcessing ? "text-gray-300" : "text-gray-500"}>
                Drop the files here ...
              </p>
            ) : (
              <p className={isProcessing ? "text-gray-300" : "text-gray-500"}>
                Drag 'n' drop some files here, or click to select files
              </p>
            )}
          </div>
          <div className="flex justify-around mt-4 h-64">
            <div className="w-1/3 flex flex-col">
              <h3 className="text-xl font-bold text-gray-800 text-center mb-4">
                Queue
              </h3>
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-blue-500">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                            >
                              Size
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">Remove</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {queue.length === 0 && (
                            <tr className="bg-white">
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 text-center"
                                colSpan={3}
                              >
                                No items in queue
                              </td>
                            </tr>
                          )}
                          {queue.map((item, itemIdx) => (
                            <tr
                              key={item.id}
                              className={
                                itemIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }
                            >
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {item.input.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {prettyBytes(item.input.size)}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <span
                                  onClick={() =>
                                    setItems((i) =>
                                      i.filter((it) => it.id !== item.id)
                                    )
                                  }
                                  className="text-red-600 hover:text-red-900"
                                >
                                  Remove
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/3 flex flex-col">
              <h3 className="text-xl text-gray-800 text-center mb-4 font-bold">
                Output
              </h3>
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-blue-500">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                          >
                            Size
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Download</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {processed.length === 0 && (
                          <tr className="bg-white">
                            <td
                              className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 text-center"
                              colSpan={3}
                            >
                              No items processed
                            </td>
                          </tr>
                        )}
                        {processed.map((item, itemIdx) => (
                          <tr
                            key={item.id}
                            className={
                              itemIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                            }
                          >
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {item.output?.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {prettyBytes(item.output?.data.size!)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <span
                                onClick={() =>
                                  saveAs(item.output!.data, item.output!.name)
                                }
                                className="text-blue-600 hover:text-blue-900"
                              >
                                Download
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-1/4">
              <Listbox
                value={outputFormat}
                onChange={(a) => {
                  if (!isProcessing) {
                    setOutputFormat(a);
                  }
                }}
                disabled={isProcessing}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                      Output Format
                    </Listbox.Label>
                    <div className="mt-1 relative">
                      <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                        <span
                          className={`block truncate ${
                            isProcessing ? "text-gray-300" : ""
                          }`}
                        >
                          {getFormatLabel(outputFormat)}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          static
                          className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        >
                          {outputFormatOptions.map((option) => (
                            <Listbox.Option
                              key={option.key}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-blue-600"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-8 pr-4"
                                )
                              }
                              value={option.key}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "block truncate"
                                    )}
                                  >
                                    {option.label}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? "text-white" : "text-blue-600",
                                        "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            {/* <div>Compression</div> */}
            <div className="w-1/4">
              <label
                htmlFor="scale"
                className="block text-sm font-medium text-gray-700"
              >
                Scale
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="scale"
                  id="scale"
                  value={scale}
                  onChange={(ev) => {
                    if (!isProcessing) {
                      setScale(parseInt(ev.target.value, 10));
                    }
                  }}
                  className={`shadow-sm block w-full sm:text-sm ${
                    isProcessing
                      ? "border-gray-200 text-gray-400"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  } rounded-md`}
                  placeholder="100%"
                  disabled={isProcessing}
                />
                <div
                  className={`absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none ${
                    isProcessing ? "text-gray-400" : ""
                  }`}
                >
                  %
                </div>
              </div>
            </div>
            <div className="rounded-md shadow">
              <button
                type="button"
                onClick={() => (isProcessing ? null : processQueue())}
                className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md md:py-4 md:text-lg md:px-10 ${
                  isProcessing
                    ? "text-gray-400 bg-gray-200 focus:border-gray-300"
                    : "text-white bg-blue-500 hover:bg-blue-600"
                }`}
              >
                {isProcessing ? (
                  <CogIcon
                    className="h-7 w-7 animate-spin text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  "Go"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
